<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="80%"
    >
      <app-project-release-request-form
        :modal="true"
        :record="record"
        :saveLoading="saveLoading"
        @cancel="doCancel"
        @submit="doSubmit"
      />
    </el-dialog>
  </div>
</template>

<script>
import ProjectReleaseRequestForm from '@/modules/project-release-request/components/project-release-request-form';
import { ProjectReleaseRequestService } from '@/modules/project-release-request/project-release-request-service';
import { i18n } from '@/i18n';
import Errors from '@/shared/error/errors';

export default {
  name: 'app-project-release-request-form-modal',

  props: ['visible', 'projectReleaseRequestId', 'record'],

  components: {
    [ProjectReleaseRequestForm.name]: ProjectReleaseRequestForm,
  },

  data() {
    return {
      /*  record: null, */
      saveLoading: false,
    };
  },

  computed: {
    dialogVisible: {
      get: function () {
        return this.visible;
      },

      set: function (value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },

    isEditing() {
      return !!this.record;
    },

    title() {
      return this.isEditing
        ? i18n('entities.projectReleaseRequest.edit.title')
        : i18n('entities.projectReleaseRequest.new.title');
    },
  },

  methods: {
    async doSubmit(payload) {
      try {
        let record;
        this.saveLoading = true;

        if (this.isEditing) {
          record = await ProjectReleaseRequestService.update(
            payload.id,
            payload.values,
          );
        } else {
          if (this.projectReleaseRequestId) {
            //set projectImplementationId so that is linked to cooperation contract
            payload.values.projectReleaseRequest = this.projectReleaseRequestId;
          }
          record = await ProjectReleaseRequestService.create(
            payload.values,
          );
        }

        this.$emit('success', record);
      } catch (error) {
        Errors.handle(error);
      } finally {
        this.saveLoading = false;
      }
    },

    doCancel() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style>
</style>
