<template>
  <div>
    <el-table
      :border="!isInPojectRelease"
      :data="rows"
      @sort-change="doChangeSort"
      ref="table"
      row-key="id"
      v-loading="loading"
    >
      <el-table-column
        type="selection"
        width="55"
        v-if="!isInPojectRelease"
      ></el-table-column>

      <el-table-column
        :label="fields.projectID.label"
        :prop="fields.projectID.name"
      >
        <template slot-scope="scope">
          <app-list-item-relation-to-one
            :label="fields.projectID.label"
            :readPermission="fields.projectID.readPermission"
            :navigatePermission="fields.projectID.navigatePermission"
            :url="fields.projectID.viewUrl"
            :value="presenter(scope.row, 'projectID')"
          ></app-list-item-relation-to-one>
        </template>
      </el-table-column>

      <el-table-column
        :label="fields.faculty.label"
        :prop="fields.faculty.name"
      >
        <template slot-scope="scope">
          <app-list-item-relation-to-one
            :label="fields.faculty.label"
            :readPermission="fields.faculty.readPermission"
            :navigatePermission="fields.faculty.navigatePermission"
            :url="fields.faculty.viewUrl"
            :value="presenter(scope.row, 'faculty')"
          ></app-list-item-relation-to-one>
        </template>
      </el-table-column>

      <el-table-column
        :label="fields.decision.label"
        :prop="fields.decision.name"
        sortable="custom"
      >
        <template v-slot="scope"
          ><el-tag
            :type="getStatusTagType(scope.row[fields.decision.name])"
            v-if="isStatusNull(scope.row[fields.decision.name])"
            >{{ presenter(scope.row, 'decision') }}</el-tag
          ></template
        >
      </el-table-column>

      <el-table-column
        :label="fields.comment.label"
        :prop="fields.comment.name"
        sortable="custom"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">{{
          presenter(scope.row, 'comment')
        }}</template>
      </el-table-column>

      <el-table-column
        :label="fields.date.label"
        :prop="fields.date.name"
        sortable="custom"
        width="180"
      >
        <template v-slot="scope">{{
          presenter(scope.row, 'date')
        }}</template>
      </el-table-column>

      <el-table-column
        :label="fields.status.label"
        :prop="fields.status.name"
        sortable="custom"
      >
        <template v-slot="scope"
          ><el-tag
            :type="getStatusTagType(scope.row[fields.status.name])"
            v-if="isStatusNull(scope.row[fields.status.name])"
            >{{ presenter(scope.row, 'status') }}</el-tag
          ></template
        >
      </el-table-column>
      <el-table-column
        :fixed="isMobile ? undefined : 'right'"
        align="center"
        v-if="isDeanshipRole || (isAdminRole && !isInPojectRelease)"
      >
        <template v-slot="scope">
          <div class="table-actions">
            <el-button
              @click="doOpenModal(scope.row)"
              v-if="hasPermissionToEdit"
              :disabled="isProcessOrEditbtnDisabled(scope.row)"
              type="primary"
              size="mini"
              plain
            >
              <app-i18n code="common.process"></app-i18n>
            </el-button>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        :fixed="isMobile ? undefined : 'right'"
        align="center"
        v-if="isAdminRole && !isInPojectRelease"
      >
        <template v-slot="scope">
          <div class="table-actions">
            <router-link
              :to="`/project-release-request/${scope.row.id}`"
            >
              <el-button type="text">
                <app-i18n code="common.view"></app-i18n>
              </el-button>
            </router-link>

            <el-button
              type="text"
              @click="doOpenModal(scope.row)"
              v-if="hasPermissionToEdit"
              :disabled="isProcessOrEditbtnDisabled(scope.row)"
            >
              <app-i18n code="common.edit"></app-i18n>
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div
      class="el-pagination-wrapper"
      v-bind:style="[isInPojectRelease ? { display: 'none' } : null]"
    >
      <el-pagination
        :current-page="pagination.currentPage || 1"
        :disabled="loading"
        :layout="paginationLayout"
        :total="count"
        @current-change="doChangePaginationCurrentPage"
        @size-change="doChangePaginationPageSize"
      ></el-pagination>
    </div>
    <portal to="modal">
      <app-project-release-request-form-modal
        :visible="dialogVisible"
        :record="record"
        @close="onModalClose"
        @success="onModalSuccess"
        v-if="dialogVisible"
      ></app-project-release-request-form-modal>
    </portal>
  </div>
</template>

<script>
import { ProjectReleaseRequestModel } from '@/modules/project-release-request/project-release-request-model';
import { mapGetters, mapActions } from 'vuex';
import { ProjectReleaseRequestPermissions } from '@/modules/project-release-request/project-release-request-permissions';
import ProjectReleaseRequestFormModal from '@/modules/project-release-request/components/project-release-request-form-modal';
import Roles from '@/security/roles';
const roles = Roles.values;

const { fields } = ProjectReleaseRequestModel;

export default {
  name: 'app-project-release-request-list-table',

  props: ['isInPojectRelease', 'releaseMode'],

  components: {
    [ProjectReleaseRequestFormModal.name]: ProjectReleaseRequestFormModal,
  },

  data() {
    return {
      dialogVisible: false,
      record: null,
    };
  },

  mounted() {
    this.doMountTable(this.$refs.table);
  },

  computed: {
    ...mapGetters({
      rows: 'projectReleaseRequest/list/rows',
      count: 'projectReleaseRequest/list/count',
      loading: 'projectReleaseRequest/list/loading',
      pagination: 'projectReleaseRequest/list/pagination',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      paginationLayout: 'layout/paginationLayout',
    }),

    hasPermissionToEdit() {
      return new ProjectReleaseRequestPermissions(this.currentUser)
        .edit;
    },

    isDeanshipRole() {
      return (
        this.currentUser.roles.includes(roles.deanship) &&
        !this.currentUser.roles.includes(roles.itAdmin) &&
        !this.currentUser.roles.includes(roles.vrAdmin)
      );
    },

    isAdminRole() {
      return (
        this.currentUser.roles.includes(roles.itAdmin) ||
        this.currentUser.roles.includes(roles.vrAdmin)
      );
    },

    fields() {
      return fields;
    },
  },

  methods: {
    ...mapActions({
      doChangeSort: 'projectReleaseRequest/list/doChangeSort',
      doChangePaginationCurrentPage:
        'projectReleaseRequest/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize:
        'projectReleaseRequest/list/doChangePaginationPageSize',
      doMountTable: 'projectReleaseRequest/list/doMountTable',
      doFetch: 'projectReleaseRequest/list/doFetch',
    }),

    presenter(row, fieldName) {
      return ProjectReleaseRequestModel.presenter(row, fieldName);
    },

    getStatusTagType(status) {
      if (status === 'approved' || status === 'processed') {
        return 'success';
      }
      if (status === 'requested' || status === 'revisionRequested') {
        return 'warning';
      }
      if (status === 'rejected' || status === 'revise') {
        return 'danger';
      }
      if (status === 'deprecated') {
        return 'info';
      }
    },

    isStatusNull(status) {
      return status === null ? false : true;
    },

    isProcessOrEditbtnDisabled(row) {
      return (
        row.status === 'processed' ||
        row.status === 'revisionRequested' ||
        row.status === 'deprecated'
      );
    },

   /*  async doReject(id) {
      try {
        await this.$confirm(
          i18n('common.areYouSure'),
          i18n('common.confirm'),
          {
            confirmButtonText: i18n('common.yes'),
            cancelButtonText: i18n('common.no'),
            type: 'warning',
          },
        );

        const values = {
          status: status.revise,
        };

        return this.doUpdateStatus({ id, values });
      } catch (error) {
        // no
      }
    }, */

   /*  async doRelease(id) {
      try {
        await this.$confirm(
          i18n('common.areYouSure'),
          i18n('common.confirm'),
          {
            confirmButtonText: i18n('common.yes'),
            cancelButtonText: i18n('common.no'),
            type: 'warning',
          },
        );

        const values = {
          status: status.approved,
        };

        return this.doUpdateStatus({ id, values });
      } catch (error) {
        // no
      }
    }, */

    doOpenModal(payload) {
      this.record = payload;
      this.dialogVisible = true;
    },

    onModalSuccess() {
      this.dialogVisible = false;

      //fetch latest data from server
      this.doFetch();
    },

    onModalClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style>
</style>
