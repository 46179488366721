<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.projectReleaseRequest.menu"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
    <!--   <h1 class="app-content-title">
        <app-i18n code="entities.projectReleaseRequest.list.title"></app-i18n>
      </h1> -->

      <app-project-release-request-list-toolbar></app-project-release-request-list-toolbar>
      <app-project-release-request-list-filter></app-project-release-request-list-filter>
      <app-project-release-request-list-table :isInPojectRelease="false"></app-project-release-request-list-table>
    </div>
  </div>
</template>

<script>
import ProjectReleaseRequestListFilter from '@/modules/project-release-request/components/project-release-request-list-filter.vue';
import ProjectReleaseRequestListTable from '@/modules/project-release-request/components/project-release-request-list-table.vue';
import ProjectReleaseRequestListToolbar from '@/modules/project-release-request/components/project-release-request-list-toolbar.vue';

export default {
  name: 'app-project-release-request-list-page',

  components: {
    [ProjectReleaseRequestListFilter.name]: ProjectReleaseRequestListFilter,
    [ProjectReleaseRequestListTable.name]: ProjectReleaseRequestListTable,
    [ProjectReleaseRequestListToolbar.name]: ProjectReleaseRequestListToolbar,
  },
};
</script>

<style>
</style>
