<template>
  <div class="app-page-toolbar">
    <router-link
      :to="{ path: '/audit-logs', query: { entityNames: 'projectReleaseRequest' } }"
      v-if="hasPermissionToAuditLogs"
    >
      <el-button icon="el-icon-fa-history">
        <app-i18n code="auditLog.menu"></app-i18n>
      </el-button>
    </router-link>
  </div>
</template>

<script>
import { AuditLogPermissions } from '@/modules/audit-log/audit-log-permissions';
import { mapGetters} from 'vuex';
import { ProjectReleaseRequestPermissions } from '@/modules/project-release-request/project-release-request-permissions';

export default {
  name: 'app-project-release-request-list-toolbar',

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      hasRows: 'projectReleaseRequest/list/hasRows',
      loading: 'projectReleaseRequest/list/loading',            
    }),

    hasPermissionToAuditLogs() {
      return new AuditLogPermissions(this.currentUser).read;
    },
    hasPermissionToEdit() {
      return new ProjectReleaseRequestPermissions(this.currentUser).edit;
    },
  },  
};
</script>

<style>
</style>
